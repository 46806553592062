.section {
  &.section-odd {
    background-color: @gray-background;
  }
  .section-container {
    max-width: @container-width;
    margin: @section-margin auto;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: @mobile-width) {
      flex-direction: column;
      align-items: stretch;
    }

    .bloc {
      flex: 1;

      display: flex;
      flex-direction: column;
      gap: @content-margin;

      padding: @content-margin;
    }

    .bloc-image {
      flex: 2;
      align-items: center;
      justify-content: center;
      gap: 10px;

      img {
        width: 100%;
        max-width: @image-width;
      }
    }

    .bloc-text {
      flex: 3;
      gap: @bloc-margin;

      background-image: url(../img/pattern-circle-black.png?width=150);
      background-position: -20px 0;
      background-repeat: no-repeat;
      background-size: 150px auto;

      .bloc-title {
        font-size: 50px;
        font-weight: 700;

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;

        margin-bottom: @content-margin;
        @media (max-width: @mobile-width) {
          font-size: 33px;
        }
      }

      .bloc-heading {
        font-size: 22px;
        font-weight: 700;
      }
    }

    .bloc-buttons {
      margin-top: @content-margin;

      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: @bloc-margin;
    }
  }
}
