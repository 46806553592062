* {
  box-sizing: border-box;
}

body {
  min-width: 400px;

  color: @page-text;
  background-color: @page-background;
}

body, button {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

strong {
  font-weight: 700;
}

a {
  color: @page-text;
  text-decoration: none;

  &:hover,
  &:active {
    color: @primary-color;
  }
}

.container {
  display: flex;
  flex-direction: column;

  margin: 40px 0;
}
