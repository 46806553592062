.button {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;

  display: inline-block;
  cursor: pointer;

  font-weight: 700;
  padding: 10px 25px;
  text-decoration: none;

  text-transform: uppercase;

  &:active {
    position: relative;
    top: 1px;
  }
}

.blue-button {
  background-color: @primary-color;
  color: @contrast-text;

  &:hover {
    background-color: darken(@primary-color, 10%);
  }
}

.white-button {
  background-color: @page-background;

  &:hover {
    background-color: darken(@page-background, 10%);
  }
}

