.section-feature {
  .feature-item {
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 35px;

    &::before {
      display: block;
      content: '✓';
      color: @primary-color;
      font-size: 32px;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
    }

    .title {
      font-weight: 700;
    }
  }
}
