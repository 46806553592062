.header {
  background-color: @page-background;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  max-width: @container-width;
  margin: 0 auto;

  @media (max-width: @mobile-width) {
    flex-direction: column;
  }

  .menu-nav {
    min-height: @header-height;
    max-height: @header-height;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: @container-width) {
      padding-left: 10px;
    }
  }
  .menu-items {
    flex: 0;
  }
}

a.logo {
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 20px;
  text-decoration: none;
  color: @primary-color;
}

/* menu */
.menu-items {
  max-height: initial;
  overflow: visible;
  transition: max-height 0.2s ease-out;

  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;

  > * {
    flex: 1;
  }

  @media (max-width: @mobile-width) {
    max-height: 0;
    overflow: hidden;

    flex-direction: column;

  }

  li {
    margin: 0;
    padding: 0;

    display: flex;
    align-content: stretch;

    border-right: 1px solid @gray-1;

    @media (max-width: @mobile-width) {
      border-right: none;
    }

    @media (max-width: @container-width) {
      &:last-of-type {
        border-right: none;
      }
    }

    &:hover {
      background-color: @gray-1;
    }
  }

  a {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 30px;

    @media (max-width: @mobile-width) {
      padding: 20px 20px;
    }
  }
}

/* menu icon */
.menu-icon {
  flex: 0;
  display: none;
  align-items: center;

  cursor: pointer;
  padding: 0 20px;
  user-select: none;

  @media (max-width: @mobile-width) {
    display: flex;
  }

  .navicon {
    background: @page-text;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;

    &:before,
    &:after {
      background: @page-text;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .2s ease-out;
      width: 100%;
    }

    &:before {
      top: 5px;
    }
    &:after {
      top: -5px;
    }
  }
}

/* menu btn */
.menu-btn {
  display: none;

  &:checked ~ .menu-items {
    max-height: 400px;
    display: flex;
  }

  &:checked ~ * .menu-icon {
    .navicon {
      background: transparent;

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    &:not(.steps) .navicon:before,
    &:not(.steps) .navicon:after {
      top: 0;
    }
  }
}
