.section.section-contact {
  color: @contrast-text;
  background-color: @primary-color;
  background-image: url(../img/pattern-vertical.png);
  background-position: bottom center;
  background-repeat: repeat-x;
  background-size: auto 200px;

  .bloc-text {
    background-image: url(../img/pattern-circle-white.png?width=150);
  }
  @media (max-width: @mobile-width) {
    .bloc-image {
      display: none;
    }
  }

  .bloc-body a {
    color: @contrast-text;
    font-weight: bold;
  }
}

