.section.section-ban {
  background-color: @primary-color;
  background-image: url(../img/bloc-ban.png);
  background-size: cover;

  text-align: center;
  color: @contrast-text;

  .section-container .bloc-text {
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}

