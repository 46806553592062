html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-cyrillic-ext-300-normal.7847706a.woff2") format("woff2"), url("montserrat-cyrillic-ext-300-normal.fe22cee3.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-cyrillic-300-normal.37f559d9.woff2") format("woff2"), url("montserrat-cyrillic-300-normal.1600c211.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-vietnamese-300-normal.85721a40.woff2") format("woff2"), url("montserrat-vietnamese-300-normal.0598776a.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-latin-ext-300-normal.0b11e791.woff2") format("woff2"), url("montserrat-latin-ext-300-normal.af72ee3f.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-latin-300-normal.158e61d8.woff2") format("woff2"), url("montserrat-latin-300-normal.eb1781eb.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-cyrillic-ext-400-normal.b3aa392f.woff2") format("woff2"), url("montserrat-cyrillic-ext-400-normal.0d7bcf38.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-cyrillic-400-normal.c6113a3d.woff2") format("woff2"), url("montserrat-cyrillic-400-normal.045ae53d.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-vietnamese-400-normal.34ae2856.woff2") format("woff2"), url("montserrat-vietnamese-400-normal.a5c007e8.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-ext-400-normal.39d79828.woff2") format("woff2"), url("montserrat-latin-ext-400-normal.93a3dac4.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-400-normal.f027985f.woff2") format("woff2"), url("montserrat-latin-400-normal.265f4e61.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-ext-700-normal.71a0f931.woff2") format("woff2"), url("montserrat-cyrillic-ext-700-normal.2ddc8d45.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-700-normal.1d14864d.woff2") format("woff2"), url("montserrat-cyrillic-700-normal.fd0dce65.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-vietnamese-700-normal.f9bc0cca.woff2") format("woff2"), url("montserrat-vietnamese-700-normal.9cbeb9bf.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-ext-700-normal.f37cf23d.woff2") format("woff2"), url("montserrat-latin-ext-700-normal.10b6d985.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.7f5cc6e4.woff2") format("woff2"), url("montserrat-latin-700-normal.71afe628.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
}

body {
  color: #000000de;
  background-color: #fff;
  min-width: 400px;
}

body, button {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

strong {
  font-weight: 700;
}

a {
  color: #000000de;
  text-decoration: none;
}

a:hover, a:active {
  color: #1976d2;
}

.container {
  flex-direction: column;
  margin: 40px 0;
  display: flex;
}

.button {
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  padding: 10px 25px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 0 5px #0003;
}

.button:active {
  position: relative;
  top: 1px;
}

.blue-button {
  color: #fff;
  background-color: #1976d2;
}

.blue-button:hover {
  background-color: #145ca4;
}

.white-button {
  background-color: #fff;
}

.white-button:hover {
  background-color: #e6e6e6;
}

.header {
  z-index: 3;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 4px #0000001a;
}

.menu {
  flex-direction: row;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
}

@media (width <= 768px) {
  .menu {
    flex-direction: column;
  }
}

.menu .menu-nav {
  flex-direction: row;
  justify-content: space-between;
  min-height: 64px;
  max-height: 64px;
  display: flex;
}

@media (width <= 1170px) {
  .menu .menu-nav {
    padding-left: 10px;
  }
}

.menu .menu-items {
  flex: 0;
}

a.logo {
  color: #1976d2;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  text-decoration: none;
  display: flex;
}

.menu-items {
  max-height: initial;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: max-height .2s ease-out;
  display: flex;
  overflow: visible;
}

.menu-items > * {
  flex: 1;
}

@media (width <= 768px) {
  .menu-items {
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
  }
}

.menu-items li {
  border-right: 1px solid #f4f4f4;
  align-content: stretch;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (width <= 768px) {
  .menu-items li {
    border-right: none;
  }
}

@media (width <= 1170px) {
  .menu-items li:last-of-type {
    border-right: none;
  }
}

.menu-items li:hover {
  background-color: #f4f4f4;
}

.menu-items a {
  flex: 1;
  align-items: center;
  padding: 0 30px;
  display: flex;
}

@media (width <= 768px) {
  .menu-items a {
    padding: 20px;
  }
}

.menu-icon {
  cursor: pointer;
  user-select: none;
  flex: 0;
  align-items: center;
  padding: 0 20px;
  display: none;
}

@media (width <= 768px) {
  .menu-icon {
    display: flex;
  }
}

.menu-icon .navicon {
  background: #000000de;
  width: 18px;
  height: 2px;
  transition: background .2s ease-out;
  display: block;
  position: relative;
}

.menu-icon .navicon:before, .menu-icon .navicon:after {
  content: "";
  background: #000000de;
  width: 100%;
  height: 100%;
  transition: all .2s ease-out;
  display: block;
  position: absolute;
}

.menu-icon .navicon:before {
  top: 5px;
}

.menu-icon .navicon:after {
  top: -5px;
}

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu-items {
  max-height: 400px;
  display: flex;
}

.menu-btn:checked ~ * .menu-icon .navicon {
  background: none;
}

.menu-btn:checked ~ * .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.menu-btn:checked ~ * .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.menu-btn:checked ~ * .menu-icon:not(.steps) .navicon:before, .menu-btn:checked ~ * .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.section.section-footer .section-container {
  margin-bottom: 0;
}

.section.section-footer .section-container .bloc.footer-summary {
  flex: 2;
}

.section.section-footer .section-container .bloc.footer-links {
  flex: 1;
  gap: 5px;
}

.section.section-footer .bloc.footer-links {
  flex-direction: column;
  display: flex;
}

.section.section-footer .bloc.footer-links a {
  white-space: nowrap;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 10px;
}

.copyright {
  text-align: center;
  color: #666;
  font-size: 14px;
  font-weight: 300;
}

.banner {
  background-image: url("banner.aa97c720.jpg");
  background-position: 50% 64px;
  background-repeat: no-repeat;
  background-size: auto max(60vh, 49.08vw, 400px);
  background-attachment: fixed;
  align-items: center;
  height: 60vh;
  min-height: 400px;
  margin-top: 64px;
  display: flex;
}

@media (width <= 1170px) {
  .banner {
    padding: 0 20px;
  }
}

.banner .banner-head {
  backdrop-filter: blur(3px);
  text-align: center;
  background: #fff3;
  border: 1px solid #fff3;
  border-radius: 10px;
  flex: 1;
  max-width: 1170px;
  margin: 0 auto;
  padding: 30px;
  box-shadow: 0 0 30px #0000001a;
}

.banner .banner-head h1 {
  color: #000000de;
  font-size: 75px;
  font-weight: 700;
}

@media (width <= 768px) {
  .banner .banner-head h1 {
    font-size: 50px;
  }
}

.banner .banner-head h2 {
  color: #000000de;
  font-size: 50px;
  font-weight: 300;
}

@media (width <= 768px) {
  .banner .banner-head h2 {
    font-size: 28px;
  }
}

.banner .banner-head .banner-buttons {
  justify-content: end;
  gap: 10px;
  margin-top: 50px;
  display: flex;
}

@media (width <= 768px) {
  .banner .banner-head .banner-buttons {
    justify-content: center;
    margin-top: 30px;
  }
}

.section.section-odd {
  background-color: #fafafa;
}

.section .section-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1170px;
  margin: 40px auto;
  display: flex;
}

@media (width <= 768px) {
  .section .section-container {
    flex-direction: column;
    align-items: stretch;
  }
}

.section .section-container .bloc {
  flex-direction: column;
  flex: 1;
  gap: 30px;
  padding: 30px;
  display: flex;
}

.section .section-container .bloc-image {
  flex: 2;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.section .section-container .bloc-image img {
  width: 100%;
  max-width: 500px;
}

.section .section-container .bloc-text {
  background-image: url("pattern-circle-black.6bd34d9c.png");
  background-position: -20px 0;
  background-repeat: no-repeat;
  background-size: 150px;
  flex: 3;
  gap: 15px;
}

.section .section-container .bloc-text .bloc-title {
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: 700;
  overflow: hidden;
}

@media (width <= 768px) {
  .section .section-container .bloc-text .bloc-title {
    font-size: 33px;
  }
}

.section .section-container .bloc-text .bloc-heading {
  font-size: 22px;
  font-weight: 700;
}

.section .section-container .bloc-buttons {
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  display: flex;
}

.section-feature .feature-item {
  flex-direction: column;
  margin: 0;
  padding: 0 0 0 35px;
  display: flex;
  position: relative;
}

.section-feature .feature-item:before {
  content: "✓";
  color: #1976d2;
  font-size: 32px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.section-feature .feature-item .title {
  font-weight: 700;
}

.section.section-ban {
  text-align: center;
  color: #fff;
  background-color: #1976d2;
  background-image: url("bloc-ban.841bcf67.png");
  background-size: cover;
}

.section.section-ban .section-container .bloc-text {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}

.section.section-contact {
  color: #fff;
  background-color: #1976d2;
  background-image: url("pattern-vertical.b8c46c94.png");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: auto 200px;
}

.section.section-contact .bloc-text {
  background-image: url("pattern-circle-white.2014b3c0.png");
}

@media (width <= 768px) {
  .section.section-contact .bloc-image {
    display: none;
  }
}

.section.section-contact .bloc-body a {
  color: #fff;
  font-weight: bold;
}

.section-x .section-container > .bloc {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
  display: flex;
}

@media (width <= 768px) {
  .section-x .section-container > .bloc {
    flex-direction: column;
  }
}

.section-x .section-container .x-item {
  text-align: center;
  border-radius: 3px;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 30px #0000001a;
}

.section-x .section-container .x-item .icon {
  justify-content: center;
  display: flex;
}

.section-x .section-container .x-item .title {
  font-size: 22px;
  font-weight: 700;
}

.section.section-terms a {
  color: #1976d2;
}

.section.section-terms h3 {
  font-size: 22px;
  font-weight: 700;
}

.section.section-terms h4 {
  font-size: 18px;
  font-weight: 700;
}

.section.section-terms ul {
  list-style: initial;
  padding-left: 20px;
}

.section.section-terms blockquote {
  color: #666;
}

.section.section-pricing input {
  display: none;
}

.section.section-pricing .pricing-selector {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section.section-pricing .pricing-selector .toggle-btn {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  display: flex;
}

.section.section-pricing .pricing-selector .switch {
  width: 54px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.section.section-pricing .pricing-selector .switch .slider {
  cursor: pointer;
  background-color: #1976d22e;
  border-radius: 30px;
  transition: transform .6s;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

.section.section-pricing .pricing-selector .switch .slider:before {
  content: "";
  background-color: #1976d2;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.section.section-pricing input:checked ~ * .slider:before {
  transform: translateX(24px);
}

.section.section-pricing input:checked ~ * .pricing-plan-list > .pricing-item .priceM {
  display: none;
}

.section.section-pricing input:checked ~ * .pricing-plan-list > .pricing-item .priceY {
  display: block;
}

.section.section-pricing .pricing-plan-list {
  flex-direction: row;
  gap: 30px;
  display: flex;
}

@media (width <= 768px) {
  .section.section-pricing .pricing-plan-list {
    flex-direction: column;
  }
}

.section.section-pricing .pricing-plan-list > .pricing-item {
  border-radius: 3px;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  padding: 20px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 30px #0000001a;
}

.section.section-pricing .pricing-plan-list > .pricing-item .title {
  font-size: 22px;
  font-weight: 700;
}

.section.section-pricing .pricing-plan-list > .pricing-item small {
  font-size: 14px;
}

.section.section-pricing .pricing-plan-list > .pricing-item .priceM {
  display: block;
}

.section.section-pricing .pricing-plan-list > .pricing-item .priceY {
  display: none;
}

.section.section-pricing .pricing-plan-list > .pricing-item .priceM, .section.section-pricing .pricing-plan-list > .pricing-item .priceY {
  text-align: center;
  font-size: 32px;
}

.section.section-pricing .pricing-plan-list > .pricing-item .list {
  text-align: left;
  flex: 1;
}

.section.section-pricing .pricing-plan-list > .pricing-item .list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 0 0 25px;
  display: block;
  position: relative;
  overflow: hidden;
}

.section.section-pricing .pricing-plan-list > .pricing-item .list li:before {
  content: "✓";
  color: #1976d2;
  font-size: 20px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.section.section-pricing .pricing-plan-list > .pricing-item .list li.disabled, .section.section-pricing .pricing-plan-list > .pricing-item .list li.disabled:before {
  color: #666;
}

.section-features .bloc-features {
  flex-direction: column;
  gap: 80px;
  margin-top: 40px;
  display: flex;
}

.section-features .feature-item {
  flex-flow: wrap-reverse;
  gap: 30px;
  display: flex;
}

.section-features .feature-item:nth-child(2n) {
  flex-direction: row-reverse;
}

.section-features .feature-item .feature-image {
  flex: 2;
  justify-content: space-around;
  align-items: start;
  display: flex;
}

.section-features .feature-item .feature-image img {
  border-radius: 5px;
  max-width: 350px;
  box-shadow: 0 0 10px #0000004d;
}

.section-features .feature-item .feature-text {
  flex-direction: column;
  flex: 3;
  gap: 5px;
  min-width: 300px;
  display: flex;
}

.section-features .feature-item .title {
  font-size: 22px;
  font-weight: 700;
}

.section-features .feature-item .bullets {
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  display: flex;
}

.section-features .feature-item .bullets > * {
  padding: 0 0 0 30px;
  position: relative;
}

.section-features .feature-item .bullets > :before {
  content: "✓";
  color: #1976d2;
  font-size: 24px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=404.b3e1fd88.css.map */
