@switch-width: 54px;
@switch-height: 30px;

.section.section-pricing {
  input {
    display: none;
  }

  .pricing-selector {
    display: flex;
    flex-direction: column;
    align-items: center;

    .toggle-btn {
      display: flex;
      flex-direction: row;
      gap: @bloc-margin;
      align-items: center;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: @switch-width;
      height: @switch-height;


      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: fade(@primary-color, 18%);

        transform: translate(0px, 0px);
        transition: 0.6s ease transform;

        border-radius: @switch-height;

        &:before {
          position: absolute;
          content: "";
          height: @switch-height - 8;
          width: @switch-height - 8;
          left: 4px;
          bottom: 4px;
          background-color: @primary-color;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }
  }

  input:checked ~ * {
    .slider:before {
      transform: translateX(@switch-width - @switch-height);
    }
    .pricing-plan-list > .pricing-item {
      .priceM {
        display: none;
      }
      .priceY {
        display: block;
      }
    }
  }

  .pricing-plan-list {
    display: flex;
    flex-direction: row;
    gap: @content-margin;

    @media (max-width: @mobile-width) {
      flex-direction: column;
    }

    > .pricing-item {
      border-radius: 3px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      padding: 20px;

      flex: 1;

      display: flex;
      flex-direction: column;
      gap: @bloc-margin;

      .title {
        font-size: 22px;
        font-weight: 700;
      }

      small {
        font-size: 14px;
      }

      .priceM {
        display: block;
      }
      .priceY {
        display: none;
      }
      .priceM, .priceY {
        text-align: center;
        font-size: 32px;
      }
      overflow: hidden;

      .list {
        text-align: left;
        flex: 1;

        li {
          position: relative;
          display: block;
          padding: 0 0 0 25px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;

          &::before {
            display: block;
            content: '✓';
            color: @primary-color;
            font-size: 20px;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
          }

          &.disabled {
            color: @secondary-color;

            &::before {
              color: @secondary-color;
            }
          }
        }
      }
    }
  }
}
