.section-features {
  .bloc-features {
    display: flex;
    flex-direction: column;
    gap: @section-margin * 2;
    margin-top: @section-margin;
  }
  .feature-item {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    gap: @content-margin;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .feature-image {
      flex: 2;

      display: flex;
      justify-content: space-around;
      align-items: start;

      img {
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        max-width: 350px;
      }
    }

    .feature-text {
      flex: 3;
      min-width: 300px;

      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .title {
      font-size: 22px;
      font-weight: 700;
    }
    .bullets {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: @bloc-margin;

      > * {
        position: relative;
        padding: 0 0 0 30px;

        &::before {
          display: block;
          content: '✓';
          color: @primary-color;
          font-size: 24px;
          line-height: 1;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
