.banner {
  margin-top: @header-height;

  background-image: url(../img/banner.jpg);
  background-attachment: fixed;
  background-position: center @header-height;
  background-repeat: no-repeat;
  background-size: auto max(@banner-height, (100vw / image-width("../img/banner.jpg") * image-height("../img/banner.jpg")), 400px);

  height: @banner-height;
  min-height: 400px;

  display: flex;
  align-items: center;

  @media (max-width: @container-width) {
    padding: 0 20px;
  }

  .banner-head {
    flex: 1;

    max-width: @container-width;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.2);

    margin: 0 auto;
    padding: 30px;

    text-align: center;

    h1 {
      color: @page-text;
      font-size: 75px;
      font-weight: 700;

      @media (max-width: @mobile-width) {
        font-size: 50px;
      }
    }

    h2 {
      color: @page-text;
      font-size: 50px;
      font-weight: 300;

      @media (max-width: @mobile-width) {
        font-size: 28px;
      }
    }

    .banner-buttons {
      margin-top: 50px;
      display: flex;
      gap: 10px;
      justify-content: end;

      @media (max-width: @mobile-width) {
        margin-top: 30px;
        justify-content: center;
      }
    }
  }
}

