.section.section-footer {
  .section-container {
    .bloc.footer-summary {
      flex: 2;
    }
    .bloc.footer-links {
      flex: 1;
      gap: 5px;
    }

    margin-bottom: 0;
  }

  .bloc.footer-links {
    display: flex;
    flex-direction: column;

    a {
      white-space: nowrap;
      padding-bottom: 10px;
      border-bottom: 1px solid @gray-1;
    }
  }
}

.copyright {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: @secondary-color;
}

