.section-x {
  .section-container {
    > .bloc {
      display: flex;

      flex-direction: row;
      align-items: stretch;
      gap: @content-margin;

      justify-content: space-between;

      @media (max-width: @mobile-width) {
        flex-direction: column;
      }
    }

    .x-item {
      flex: 1;
      border-radius: 3px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: @bloc-margin;

      text-align: center;

      .icon {
        display: flex;
        justify-content: center;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
}
