.section.section-terms {
  a {
    color: @primary-color;
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  ul {
    list-style: initial;
    padding-left: 20px;
  }
  blockquote {
    color: @secondary-color;
  }
}
